.about-us-title {
    /* background-image: url('path/to/background-image.jpg'); */
    background-size: cover;
    color: white;
    text-align: center;
    padding: 100px 0;
}

.about-company, .how-we-work {
    padding: 100px 0;
}

.about-company img, .how-we-work img {
    max-height: 300px;
}