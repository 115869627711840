@tailwind base;
@tailwind components;


@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800&display=swap');



body {
  margin: 0;
   padding: 0;
   overflow-x: hidden !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loader-budget{
  position: fixed;
  top: 0;left: 0;
  height:100%;
  width:100%;
  z-index: 111;
  background-color: #fdfdfd;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.loader-budget>img{
  position: absolute;
 width:50%;
 /* -webkit-animation: spin 2s linear infinite;
 animation: spin 2s linear infinite; */
  
}
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* collections */
.coll-img{
  position: relative;

  transition: all ease-in-out !important;
}
.collectionName{
  position: absolute;
  display: none;
  top: 20px;
  left: 10px;
  z-index:inherit;
  color: black;
  transition: all ease-in-out !important;
  
}
/* product details */

/* .zoom1 .zoom-magnify img{
   width: 70% !important;
   border:1px solid red !important;
} */



.coll-img:hover .collectionName{
    display: block;
}
@media screen and (max-width:870px){
  .collectionName{
    display: block !important;
    font-size: 12px;
  }
}

/* testing */
.perimeter {
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  border: 1px solid red;
}

.image {
  flex: 0 0 100%;
}

.copy {
  padding: 20px;
  font-family: sans-serif;
}

@media (min-width: 415px) {
  .perimeter {
    flex-direction: row;
  }
  
  .image {
    flex: 0 0 50%;
   
  }
}

@media (min-width: 800px) {
  .image {
    flex: 0 0 33.5%;
    border: 1px solid green;
  
  }
}