

.login{
    max-width: 1300px;
    margin:0 auto 
}

.login-left{
  
}
.login-right{
    
    background-color: transparent ;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-radius: 8px;
    position: relative;
    box-shadow: 0 0 18px 0 rgba(0,0,0,.18);
}
.login-right::before{
    content:" ";
    position: absolute;
    top:0;
    left:0;
    height:100%;
    width: 100%;
    background: url('/public/images/bglogin.png') no-repeat center;
    z-index: -1;
    background-size:cover;
}
.labelAndInput {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    width: 100%;
}
.labelAndInput label {
    font-size: 14px;
    color: #000;
    font-weight: 500;
    margin-bottom: 10px;
}
.labelAndInput  input, .labelAndInput  select {
    border: 1px solid #ddd;
    border-radius: 4px;
    border-radius: 5.74011px;
    box-shadow: 0 0.717514px 4.30509px rgba(0,0,0,.15);
    font-size: 15px;
    margin-bottom: 20px;
    outline: none;
    transition: all .4s;
    width: 100%;
    padding: 8px 25px;
}

.otpform{
    width: 100%;
    padding: 10px;
    max-width: 500px;
    margin:  0 auto ;
}

.otpWrapper {
    display: flex;
    flex-direction: row;
  grid-Gap:40px;
    align-items: center;
    width: 100%;
    margin-top: 15px;
}

.otpInput {
    width: 20%;
    height: 50px;
    text-align: center;
    font-size: 15px;
    outline: none;
    transition: all 0.4s;
    max-width: 100px;
    /* margin:  0 auto ; */
    border: none;
    border-bottom: 2px solid #c4c4c4
}

@media screen and (max-width:1000px){
    .login-left{
        display: none !important;
    }
    .login-right{
    max-width:500px;
    flex:1 !important;
    }
    
}
