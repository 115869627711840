.top-button{
    padding:3rem 0;
    border-bottom: 3px solid white;
    transition: border 0.4s ease-in-out;
    cursor:pointer;
}


/* ------------profile--------------- */
.edit-profile-container{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
border-radius:10px
}
.edit-profile-container-left,.edit-profile-container-right{
   flex:1
}
.edit-profile-container-left>img{
   border-radius: 50%;
   width: 200px;
   height: 200px;
   
   margin: 0 auto;
}
.editprofile-input{
    display: flex;
    grid-gap :20px;
    align-items: center;
    margin-bottom: 20px;
}
.editprofile-input>label{
   width: 200px;
   font-size:13px;
   font-weight: 600;

}
.editprofile-input>input,.profile-input{
    max-width: 400px;
    border: 1px solid #ddd;
    border-radius: 4px;
    border-radius: 5.74011px;

    font-size: 14px;
   
    outline: none;
    transition: all .4s;
    width: 100%;
    padding: 6px 24px;

}
.imageinput{
margin: 1rem auto;
width: 106px;

}

@media screen and (max-width:800px) {
    .edit-profile-container{
        flex-direction: column;
        grid-gap:40px
    }
    
}

/* ------------------modal address-------------------------- */

.radio {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 26px;
    height: 27px;
    border-radius: 100% !important;
    margin-right: 10px;
    border: 1.5px solid #e2bc3f;
    transition: 0.2s ease-in-out;
}




    
    .modale-Addaddress>.modal-dialog{
        width:70% !important;
         max-width:1000px !important;
    }
    @media screen and (max-width:550px){
        .modale-Addaddress>.modal-dialog{
            width:97% !important;
            
        }
    }


    /* contact usb */

    .about-us-top {
        background-position: 50%!important;
        background-repeat: no-repeat!important;
        background-size: cover!important;
        padding-bottom: 12rem;
        padding-top: 10rem;
        position: relative;
        z-index: 0;
    }

    .about-us-top h1 {
        color: #fff!important;
        font-size: 3rem;
        font-weight: 700;
        text-align: center;
    }
.free-consulting-conatiner{
    display: flex;
    grid-gap:80px;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
    
}
.free-consulting-conatiner>div{
   flex:1
    
}

.content-freeconsult>h2{
    padding: 0 0 8px;
    border-bottom: 2px solid #64c1c5;
    
    margin-bottom: 2rem;
    display: inline-block;
    font-weight: 300;

}
.content-freeconsult>h6{
    
    margin-bottom: 2rem;
    text-transform: uppercase;
    font-weight: 600;
    
}
.content-freeconsult>p{
    
    word-spacing: 5px;
    color:grey;
    line-height: 24px;

    
}

#free-consulting-img{
    width: 500px;
    height: 350px;
    object-fit: cover;



}
.free-consult-form{
    display: grid;
    grid-column-gap: 30px;
}
.free-consult-form>div>input{
    height: 50px !important;
    border-radius: 4px;
}
.free-consult-form>div:nth-child(1){
    grid-column: 1 / 2;
}
.free-consult-form>div:nth-child(2){
    grid-column: 2 / 3;
}
.free-consult-form>div:nth-child(3){
    grid-column: 1 / 2;
}
.free-consult-form>div:nth-child(4){
    grid-column: 2 / 3;
}
.free-consult-form>div:nth-child(5){
    grid-column: 1 / 3;
}
.free-consult-form>button{
    grid-column: 1 / 3;
}
@media screen and (max-width:1200px) {
    .free-consulting-conatiner{
        flex-direction: column !important;
        grid-gap:40px
        
    }
    #free-consulting-img{
        width: 100%;
        height: 350px;
        object-fit: cover;
    
    
    
    }
    
}
@media screen and (max-width:450px) {
    .free-consulting-conatiner{
       
        grid-gap:20px
        
    }
   
    
}

