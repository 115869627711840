 /* Custom Styles */
 .contact-banner {
    /* background-image: url('banner-image.jpg'); */
    background-size: cover;
    background-position: center;
    color: #fff;
    text-align: center;
    padding: 150px 0;
  }
  .contact-banner h2 {
    font-size: 36px;
  }
  .contact-info {
    background-color: #f9f9f9;
    padding: 50px 0;
  }
  .contact-form {
    padding: 30px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  .contact-form input[type="text"],
  .contact-form input[type="email"],
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .contact-form button {
    background-color: #ff6600;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  .contact-form button:hover {
    background-color: #e65100;
  }
  .contact-info img {
    max-width: 100%;
  }
  .contact-map {
    position: relative;
    padding-top: 50%; /* Aspect ratio for the map container */
  }
  .contact-map iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }